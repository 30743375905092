body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
.lbhd4px2ci9-editor_css {
  font-size: 48px;
  font-weight: bold;
}
.secondTitle {
  font-size: 16px;
  letter-spacing: 6px;
  color: rgba(0, 0, 0, 0.2);
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.header2Show {
  display: block;
}
.header2Hidden {
  display: none;
}
.addNavClass .header2 {
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.01), 0px 4px 5px 0px rgba(0, 0, 0, 0.02), 0px 1px 4px -1px rgba(0, 0, 0, 0.06);
  background: #ffffff;
}
.addNavClass .header2 .header2-menu > .menu-item {
  color: #666666;
}
.addNavClass .header2 .header2-menu > .menu-item:first-child {
  color: #666666 !important;
}
.addNavClass .header2 .header2-menu > .menu-item:first-child:hover {
  color: #004BC7 !important;
}
.addNavClass .header2 .header2-menu > .menu-item.active,
.addNavClass .header2 .header2-menu > .menu-item:hover {
  color: #004BC7;
}
.header2 {
  background: transparent;
  width: 100%;
  z-index: 1;
  position: relative;
  top: 0;
}
.header2 .home-page {
  padding: 0 24px;
  height: 70px;
}
.header2-logo {
  display: inline-block;
  position: relative;
  width: 99px;
  line-height: 43px;
  margin-top: 13px;
}
.header2-logo img {
  vertical-align: middle;
  display: inline-block;
}
.header2-logo a {
  display: block;
}
.header2-menu {
  float: right;
}
.header2-menu > .menu-item {
  color: #ffffff;
  font-size: 18px;
  line-height: 70px;
  display: inline-block;
  padding: 0 20px;
  cursor: pointer;
  background: transparent;
  height: 70px;
  border-bottom-color: transparent;
  position: relative;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.header2-menu > .menu-item:first-child {
  color: #ffffff !important;
}
.header2-menu > .menu-item:first-child:hover {
  color: #004BC7 !important;
}
.header2-menu > .menu-item.active,
.header2-menu > .menu-item:hover {
  color: #004BC7;
}
@media screen and (max-width: 767px) {
  .addNavClass {
    display: none;
  }
  .header2-logo {
    z-index: 101;
    margin-left: calc(50% - 46px);
    margin-top: 11px;
  }
  .header2-logo img {
    height: 34px;
    width: auto;
  }
  .header2.home-page-wrapper .home-page {
    padding: 0 24px;
  }
  .header2 .triangle {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #ffffff;
    position: absolute;
    right: 16px;
    top: 49px;
    display: none;
  }
  .header2-menu {
    height: auto;
    float: inherit;
    position: relative;
    left: calc(50% + 62px);
    width: 120px;
    opacity: 0;
    overflow: hidden;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header2-menu .menu-item {
    display: block;
    width: 100%;
    padding: 0 24px;
    border-bottom: none;
    line-height: 40px;
    height: 40px;
    margin: 4px 0 8px;
  }
  .header2-menu .menu-item.active,
  .header2-menu .menu-item:hover {
    border: none;
    color: #004BC7;
  }
  .header2-mobile-menu {
    width: 24px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 16px;
    z-index: 100;
  }
  .header2-mobile-menu em {
    display: block;
    width: 100%;
    height: 3px;
    background: #FFFFFF;
    margin-top: 4px;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header2-mobile-menu em:nth-child(2) {
    width: 14px;
    margin-left: 10px;
  }
  .header2-mobile-menu em:nth-child(3) {
    opacity: 0;
  }
  .header2-mobile-menu :first-child {
    margin-top: 0;
  }
  .header2 .middleCon .middle_title {
    height: 33px;
    font-size: 24px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 33px;
  }
  .header2 .open {
    height: auto;
  }
  .header2 .open .header2-mobile-menu em:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
  }
  .header2 .open .header2-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header2 .open .header2-mobile-menu em:nth-child(3) {
    margin-top: 2px;
    opacity: 1;
    transform: translateY(-6px) rotate(-45deg);
  }
  .header2 .open .header2-menu {
    opacity: 1;
    pointer-events: auto;
    background: #ffffff;
  }
  .header2 .open .header2-menu > .menu-item {
    color: #000 !important;
    font-size: 15px;
    font-weight: normal !important;
  }
  .header2 .open .triangle {
    display: block;
  }
  #Banner5_0 .banner5-page .middleCon {
    width: 100% !important;
  }
  #Banner5_0 .banner5-page .middleCon .middle_title {
    height: 33px !important;
    font-size: 24px !important;
    color: #FFFFFF !important;
    line-height: 33px !important;
    text-align: center;
  }
  #Banner5_0 .banner5-page .middleCon .middle_describe {
    height: 20px !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #FFFFFF !important;
    line-height: 20px !important;
    margin-top: 8px !important;
  }
  #Banner5_0 .banner5-page .middleCon .middle_consult .middle_btn {
    width: 80px !important;
    height: 32px !important;
    background: #004BC7;
    border-radius: 2px !important;
    font-size: 14px !important;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 32px !important;
    text-align: center;
    float: left;
    position: relative;
  }
  #Banner5_0 .banner5-page .middleCon .middle_consult .middle_btn .middle_btn1_span {
    color: #FFFFFF;
  }
  #Banner5_0 .banner5-page .middleCon .middle_consult .middle_btn:hover {
    cursor: pointer;
    background: #ffffff;
    color: #004BC7;
  }
  #Banner5_0 .banner5-page .middleCon .middle_consult .middle_btn:hover .middle_btn1_span {
    background: #004BC7 !important;
    color: #ffffff !important;
  }
  #Banner5_0 .banner5-page .middleCon .middle_consult .middle_btn:hover .middle_btn1_1 {
    display: none !important;
  }
  #Banner5_0 .banner5-page .middleCon .middle_consult .middle_btn:hover .middle_btn2_1 {
    display: block;
  }
  #Banner5_0 .banner5-page .middleCon .middle_consult .middle_btn1 {
    margin-left: 0 !important;
  }
  #Banner5_0 .banner5-page .middleCon .middle_consult .middle_btn1:hover {
    background: #004BC7 !important;
  }
  #Banner5_0 .banner5-page .middleCon .middle_consult .middle_btn1:hover .middle_btn1_span p {
    color: #ffffff !important;
  }
  #Banner5_0 .banner5-page .middleCon .middle_consult .middle_btn2 {
    margin-left: 16px !important;
  }
  #Banner5_0 .banner5-page .middleCon .middle_consult {
    margin-top: 32px !important;
    display: flex;
    justify-content: center;
  }
  #Banner5_0 .banner5-page .middleCon .middle_consult .middle_btn .middle_btn1_1 {
    width: 160px;
    height: 40px;
    font-size: 16px;
    background: #FFFFFF;
    box-shadow: 0px 6px 30px 5px rgba(0, 0, 0, 0.05), 0px 16px 24px 2px rgba(0, 0, 0, 0.04), 0px 8px 10px -5px rgba(0, 0, 0, 0.08);
    border-radius: 2px;
    color: #1A1A1A;
    line-height: 40px;
    margin-top: 8px;
    margin-left: -40px;
  }
  #Banner5_0 .banner5-page .middleCon .middle_consult .middle_btn .middle_btn1_1 .middle_btn1_1_img {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
  #Banner5_0 .banner5-page .middleCon .middle_consult .middle_btn .middle_btn2_1 {
    width: 160px;
    background: #FFFFFF;
    padding-bottom: 12px;
    margin-top: 8px;
    margin-left: -40px;
    border-radius: 2px;
  }
}
.banner5 {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #fdfdfd;
  background-image: linear-gradient(to top, rgba(253, 253, 253, 0.3) 0%, rgba(193, 218, 255, 0.3) 100%);
  overflow: hidden;
}
.banner5 .banner5-page {
  padding: 1px 0 0;
}
.banner5-title {
  font-size: 48px;
  line-height: 1.5;
  color: #5f9bf1;
  margin-bottom: 8px;
}
.banner5-title-wrapper {
  width: 40%;
  max-width: 450px;
  position: relative;
  top: 30%;
  left: 24px;
  font-family: 'PingFang SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.banner5-explain {
  color: #333;
  font-size: 24px;
  margin-bottom: 24px;
}
.banner5-content {
  font-size: 18px;
  color: #666;
  margin-bottom: 48px;
}
.banner5-button-wrapper .banner5-button {
  background-image: linear-gradient(to right bottom, #82b5ff 0%, #3c89f6 0%);
  box-shadow: 0 8px 24px rgba(95, 155, 241, 0.35);
  border-radius: 20px;
  border: none;
  padding: 0 42px;
  line-height: 40px;
  height: 40px;
  transition: transform 0.3s, box-shadow 0.3s;
}
.banner5-button-wrapper .banner5-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 13px 24px rgba(95, 155, 241, 0.45);
  background-image: linear-gradient(to right bottom, #82b5ff 0%, #3c89f6 0%);
}
.banner5-image {
  overflow: hidden;
  width: 55%;
  max-width: 710px;
  position: absolute;
  top: 15%;
  right: 24px;
}
@media screen and (max-width: 767px) {
  .banner5 {
    min-height: 500px;
  }
  .banner5 .banner5-page.home-page {
    padding-top: 1px;
    padding-bottom: 0;
  }
  .banner5-title-wrapper {
    text-align: center;
    position: static;
    width: 100%;
    margin-top: 64px;
    left: 0;
    top: 0;
  }
  .banner5-title {
    font-size: 32px;
  }
  .banner5-explain {
    font-size: 18px;
  }
  .banner5-content {
    font-size: 14px;
  }
  .banner5-image {
    position: static;
    width: 100%;
    top: 0;
    right: 0;
    margin: 32px 0 24px;
  }
}
#video {
  width: 100vw;
  object-fit: cover;
  object-position: 50% 60%;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 6;
}
.feature6-wrapper {
  min-height: 360px;
  margin: 0 auto;
  overflow: hidden;
}
.feature6-wrapper.home-page-wrapper .home-page {
  padding: 64px 24px;
}
.feature6-title {
  display: inline-block;
}
.feature6-title-wrapper {
  text-align: center;
  margin-bottom: 48px;
}
.feature6-title-text {
  display: inline-block;
  margin: 0 72px;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.85);
  transition: color 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
}
.feature6-title-text.active {
  color: #1890ff;
}
.feature6-title-bar {
  height: 6px;
  background: #1890ff;
  width: 20%;
  margin: auto;
  display: block;
}
.feature6-title-bar-wrapper {
  position: relative;
  margin-top: 8px;
  transition: left 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.feature6-number,
.feature6-text {
  text-align: center;
}
.feature6-number {
  font-size: 48px;
  color: #1890ff;
  font-weight: 600;
}
.feature6-number-wrapper {
  text-align: center;
}
.feature6-text {
  font-size: 20px;
}
.feature6-unit {
  font-size: 16px;
  color: #1890ff;
}
.feature6 .ant-carousel .slick-dots-bottom {
  bottom: -24px;
}
.feature6 .ant-carousel .slick-dots li button {
  background: rgba(24, 144, 255, 0.3);
}
.feature6 .ant-carousel .slick-dots li.slick-active button {
  background: #1890ff;
}
@media screen and (max-width: 767px) {
  .feature6-wrapper {
    padding-bottom: 0;
    min-height: 580px;
  }
  .feature6-wrapper.home-page-wrapper .home-page {
    padding: 56px 24px 64px;
  }
  .feature6-title-text {
    margin: 0 14px;
  }
  .feature6-title-bar {
    width: 40%;
  }
  .feature6-number-wrapper {
    margin-bottom: 16px;
  }
}
.content0-wrapper {
  min-height: 446px;
  overflow: hidden;
}
.content0-wrapper .content0 {
  height: 100%;
  padding: 140px 0;
}
.content0-wrapper .content0 > .title-wrapper {
  margin: 0 auto 48px;
}
.content0-wrapper .content0-title-editor_css {
  font-size: 24px;
  font-weight: bold;
  color: #1A1A1A;
  line-height: 33px;
  text-align: left;
  margin-bottom: 20px;
  margin-top: 28px;
}
.content0-wrapper .content0-title-editor_css::before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #000000;
  line-height: 33px;
  margin-right: 12px;
  margin-bottom: 5px;
}
.content0-wrapper .content0-title-editor_css .secondBigTitle1-editor_css {
  color: #004BC7;
}
.content0-wrapper .content0-span-editor_css {
  display: block;
  font-size: 16px;
  font-weight: 400;
  color: #1A1A1A;
  line-height: 22px;
  text-align: left;
  margin-bottom: 16px;
  margin-left: 32px;
}
.content0-wrapper .content0-span-editor_css .content0-span {
  color: #1A1A1A;
}
.content0-wrapper .content0-span-editor_css .content0-span:hover {
  cursor: pointer;
  color: #004BC7;
}
.content0-wrapper .content0-span-img {
  display: none;
}
.content0-wrapper .content0-span1::before {
  content: '01';
  display: inline-block;
  height: 22px;
  font-size: 16px;
  font-weight: 400;
  color: #004BC7;
  line-height: 22px;
  margin-right: 11px;
  margin-left: -32px;
}
.content0-wrapper .content0-span2::before {
  content: '02';
  display: inline-block;
  height: 22px;
  font-size: 16px;
  font-weight: 400;
  color: #004BC7;
  line-height: 22px;
  margin-right: 11px;
  margin-left: -32px;
}
.content0-wrapper .content0-span3::before {
  content: '03';
  display: inline-block;
  height: 22px;
  font-size: 16px;
  font-weight: 400;
  color: #004BC7;
  line-height: 22px;
  margin-right: 11px;
  margin-left: -32px;
}
.content0-wrapper .content0-span4::before {
  content: '04';
  display: inline-block;
  height: 22px;
  font-size: 16px;
  font-weight: 400;
  color: #004BC7;
  line-height: 22px;
  margin-right: 11px;
  margin-left: -32px;
}
.content0-wrapper .content0-span5::before {
  content: '05';
  display: inline-block;
  height: 22px;
  font-size: 16px;
  font-weight: 400;
  color: #004BC7;
  line-height: 22px;
  margin-right: 11px;
  margin-left: -32px;
}
.content0-wrapper .content0-block {
  padding: 0;
  display: inline-block;
  text-align: center;
  min-height: 200px;
  overflow: hidden;
  border-radius: 4px;
}
.content0-wrapper .content0-block img {
  width: 100%;
}
.content0-wrapper .content0-block img:hover {
  transform: scale(1.1);
  transition: transform 0.5s ease;
}
.content0-wrapper .content0-block-wrapper {
  position: relative;
  height: 100%;
  top: 25%;
  background-color: #ffffff;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.1), 0px 8px 10px 1px rgba(0, 0, 0, 0.06), 0px 3px 14px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
.content0-wrapper .content0-block.queue-anim-leaving {
  position: relative !important;
}
.content0-wrapper .content0-block-icon {
  margin: auto;
}
.content0-wrapper .content0-block-title {
  line-height: 32px;
  margin: 10px auto;
  font-size: 24px;
}
.content0-wrapper .content0-block-box {
  border-radius: 4px 0px 0px 4px !important;
}
.content0-wrapper .content0-block1 {
  padding: 0 4%;
}
.content0-wrapper .content0-block1-wrapper {
  margin-top: 30px;
  background: #FFFFFF;
}
.content0-wrapper .content0-block3 {
  margin-right: 30px;
}
.content0-wrapper .content0-block3,
.content0-wrapper .content0-block4 {
  max-width: 585px;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.1), 0px 8px 10px 1px rgba(0, 0, 0, 0.06), 0px 3px 14px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
.content0-wrapper .content0-ting-editor_css {
  display: block;
  position: relative;
  overflow: hidden;
}
.content0-wrapper .content0-ting-editor_css img:hover {
  transform: scale(1.1);
  transition: transform 0.5s ease;
}
.content0-wrapper .content0-ting-wj-editor_css {
  color: #ffffff;
  position: absolute;
  bottom: 16px;
  left: 32px;
  font-size: 24px;
  font-weight: bold;
}
.content0-wrapper .content0-ting-wj-editor_css::before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #ffffff;
  margin-right: 12px;
  margin-bottom: 5px;
}
.content0-wrapper .content0-ting-wj-des {
  display: block;
  font-size: 16px;
  font-weight: 400;
  color: #1A1A1A;
  line-height: 28px;
  text-align: left;
  margin: 16px 32px;
}
.content0-wrapper .content0-ting-wj-des-item {
  display: inline-block;
  text-align: center;
  width: 70px;
  height: 24px;
  background: rgba(236, 242, 254, 0.5);
  height: 22px;
  font-size: 16px;
  font-weight: 400;
  color: #004BC7;
  line-height: 22px;
  margin-right: 16px;
}
@media screen and (max-width: 767px) {
  .content0-wrapper {
    min-height: 1395px;
  }
  .content0-wrapper .content0-block1-wrapper {
    margin-top: 12px;
  }
  .content0-wrapper .content0-block1 {
    padding-bottom: 7px;
  }
  .content0-wrapper .content0-block3,
  .content0-wrapper .content0-block4 {
    box-shadow: none;
  }
  .content0-wrapper .content0 {
    padding: 32px 0 24px 0;
  }
  .content0-wrapper .content0 > .title-wrapper {
    margin: 0 auto 12px !important;
  }
  .content0-wrapper .lbhd4px2ci9-editor_css {
    height: 33px;
    font-size: 24px;
    font-weight: bold;
    color: #1A1A1A;
    line-height: 33px;
  }
  .content0-wrapper .secondTitle {
    height: 11px;
    font-size: 8px !important;
    font-weight: 300;
    color: #1A1A1A;
    line-height: 11px;
    letter-spacing: 3px !important;
  }
  .content0-wrapper .content0-block3-item,
  .content0-wrapper .content0-block4-item {
    margin: 0 16px;
    background: #FFFFFF;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.05), 0px 4px 5px 0px rgba(0, 0, 0, 0.08), 0px 1px 4px -1px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
  }
  .content0-wrapper .content0-block3-item {
    margin-bottom: 12px;
  }
  .content0-wrapper .content0-block-wrapper {
    overflow: hidden;
    margin: 0 16px;
  }
  .content0-wrapper .content0-wrapper .content0-block3,
  .content0-wrapper .content0-wrapper .content0-block4 {
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.05), 0px 4px 5px 0px rgba(0, 0, 0, 0.08), 0px 1px 4px -1px rgba(0, 0, 0, 0.12);
  }
  .content0-wrapper .content0-block-box {
    border-radius: 0px !important;
  }
  .content0-wrapper .content0-title-editor_css {
    height: 20px;
    font-size: 14px;
    font-weight: 600;
    color: #1A1A1A;
    line-height: 20px;
    margin: 12px 0 0px;
  }
  .content0-wrapper .content0-title-editor_css::before {
    content: '';
    width: 6px;
    height: 6px;
    background: #1a1a1a;
    margin-right: 6px;
    margin-bottom: 3px;
  }
  .content0-wrapper .content0-span-editor_css {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px solid #dedede;
    padding: 8px 0;
    margin-left: 0 !important;
    margin-bottom: 0 !important;
  }
  .content0-wrapper .content0-span-editor_css:last-child {
    border-bottom: 0px;
  }
  .content0-wrapper .content0-span-editor_css .content0-span {
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    color: #1A1A1A;
    margin-left: 20px;
  }
  .content0-wrapper .content0-span-editor_css .content0-span::before {
    height: 17px;
    font-size: 12px;
    line-height: 17px;
    margin-right: 4px;
    margin-left: -20px !important;
  }
  .content0-wrapper .content0-span-editor_css .content0-span-img {
    display: block;
    width: 54px;
    height: 54px;
    object-fit: cover;
    margin-left: 8px;
    border-radius: 2px;
  }
  .content0-wrapper .content0-ting-wj-editor_css {
    height: 20px;
    font-size: 14px;
    font-weight: 600;
    color: #1A1A1A;
    line-height: 20px;
    position: static;
    text-align: left;
    margin: 12px 0 10px 12px;
  }
  .content0-wrapper .content0-ting-wj-editor_css::before {
    content: '';
    width: 6px;
    height: 6px;
    background: #1a1a1a;
    margin-right: 6px;
    margin-bottom: 3px;
  }
  .content0-wrapper .content0-ting-wj-des-item {
    width: 50px;
    height: 18px;
    background: rgba(236, 242, 254, 0.5);
    font-size: 12px;
    font-weight: 400;
    color: #004BC7;
    line-height: 17px;
    margin-bottom: 8px;
  }
  .content0-wrapper .content0-ting-wj-des {
    font-size: 12px;
    font-weight: 400;
    color: #1A1A1A;
    line-height: 17px;
    margin: 0;
    padding: 0 12px 6px 12px;
  }
}
.content1-wrapper .content1 {
  height: 100%;
  padding: 0;
}
.content1-wrapper .content1-block-wrapper {
  overflow: hidden;
  border-radius: 2px;
}
.content1-wrapper .content1-block-item {
  height: 660px;
  width: 300px;
  overflow: hidden;
}
.content1-wrapper .content1-block-item:hover .content1-block-img {
  transform: scale(1.2);
  transition: transform 0.5s ease;
}
.content1-wrapper .content1-block-item:hover .content1-block-span {
  display: block;
  background: linear-gradient(180deg, rgba(26, 26, 26, 0.8) 0%, rgba(26, 26, 26, 0) 100%);
  font-size: 20px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 28px;
}
.content1-wrapper .content1-block-item:hover .content1-block-span .content1-block-span-title {
  color: #FFFFFF;
}
.content1-wrapper .content1-block-item:hover .content1-block-span .content1-block-span-line {
  background: #FFFFFF;
}
.content1-wrapper .content1-block-item:hover .content1-block-span .content1-block-span-on {
  display: block;
}
.content1-wrapper .content1-block-item:hover .content1-block-span .content1-block-span-on .content1-block-span-on-h {
  display: none;
}
.content1-wrapper .content1-block-item-hover .content1-block-img {
  transform: scale(1.2);
  transition: transform 0.5s ease;
}
.content1-wrapper .content1-block-item-hover .content1-block-span {
  display: block;
  background: linear-gradient(180deg, rgba(26, 26, 26, 0.8) 0%, rgba(26, 26, 26, 0) 100%);
  font-size: 20px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 28px;
}
.content1-wrapper .content1-block-item-hover .content1-block-span .content1-block-span-title {
  color: #FFFFFF;
}
.content1-wrapper .content1-block-item-hover .content1-block-span .content1-block-span-line {
  background: #FFFFFF;
}
.content1-wrapper .content1-block-item-hover .content1-block-span .content1-block-span-on {
  display: block;
}
.content1-wrapper .content1-block-item-hover .content1-block-span .content1-block-span-on .content1-block-span-on-h {
  display: none;
}
.content1-wrapper .content1 .content1-block-img-span {
  width: 300px;
}
.content1-wrapper .content1 .content1-block-img-span img {
  width: 100%;
}
.content1-wrapper .content1-block-span-num {
  font-size: 20px;
}
.content1-wrapper .content1-block-span-inner {
  display: block;
  padding: 40px;
}
.content1-wrapper .content1-block-span-line {
  width: 16px;
  height: 4px;
  background: #FFFFFF;
  margin-top: 12px;
  margin-bottom: 20px;
}
.content1-wrapper .content1-block-icon {
  position: relative;
}
.content1-wrapper .content1-block-span {
  height: 660px;
  width: 300px;
  display: block;
  position: absolute;
  top: 0;
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}
.content1-wrapper .content1-block-span .content1-block-span-title {
  color: #001B82;
  font-weight: 900;
}
.content1-wrapper .content1-block-span .content1-block-span-line {
  background: #001B82;
}
.content1-wrapper .content1-block-span .content1-block-span-on {
  display: none;
  line-height: 28px;
}
.content1-wrapper .content1-block-span-title {
  font-size: 24px;
  line-height: 33px;
}
.content1-wrapper .content1-block-item {
  max-height: 660px;
  display: block;
  background: linear-gradient(180deg, rgba(26, 26, 26, 0.8) 0%, rgba(26, 26, 26, 0) 100%);
}
.content1-wrapper .content1-text {
  padding: 0 32px;
  height: 100%;
}
.content1-wrapper .content1-text .content1-content,
.content1-wrapper .content1-text .content1-title {
  position: relative !important;
}
.content1-wrapper .content1-text .content1-title {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
  margin-top: 120px;
}
.content1-wrapper .content1-text .content {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  #Content1_0 {
    padding-bottom: 0 !important;
    padding-top: 16px !important;
  }
  .home-page-wrapper .title-wrapper {
    margin: 0 auto 12px !important;
  }
  .content1-wrapper {
    min-height: 912px;
  }
  .content1-wrapper .content1-block-item {
    background: none;
  }
  .content1-wrapper .lbhd4px2ci9-editor_css {
    height: 33px;
    font-size: 24px;
    font-weight: bold;
    color: #1A1A1A;
    line-height: 33px;
  }
  .content1-wrapper .secondTitle {
    height: 11px;
    font-size: 8px !important;
    font-weight: 300;
    color: #1A1A1A;
    line-height: 11px;
    letter-spacing: 3px !important;
  }
  .content1-wrapper .content1-block-span-inner {
    padding: 12px;
  }
  .content1-wrapper .content1-block-span-on {
    display: flex;
  }
  .content1-wrapper .content1-block-span-on .content1-block-span-on-h {
    display: block;
  }
  .content1-wrapper .content1-block-span-on-box {
    display: flex;
    flex-wrap: wrap;
  }
  .content1-wrapper .content1-block-span {
    width: 100%;
    height: 198px !important;
  }
  .content1-wrapper .content1-block-icon {
    margin: 0 16px;
    border-radius: 4px;
    overflow: hidden;
  }
  .content1-wrapper .content1-block-span-title {
    height: 20px;
    font-size: 14px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 20px;
  }
  .content1-wrapper .content1-block-span-line {
    width: 8px;
    height: 2px;
    background: #FFFFFF;
    margin-top: 2px;
    margin-bottom: 8px;
  }
  .content1-wrapper .content1-block-span-on,
  .content1-wrapper .content1-block-span-num {
    font-size: 12px;
    font-weight: 400;
    color: #FFFFFF;
    white-space: nowrap;
  }
  .content1-wrapper .content1-block-item {
    width: 100%;
    height: 198px !important;
    border-radius: 2px;
    overflow: hidden;
  }
  .content1-wrapper .content1-block-item .content1-block-span {
    display: block;
    background: linear-gradient(180deg, rgba(26, 26, 26, 0.8) 0%, rgba(26, 26, 26, 0) 100%) !important;
    font-size: 20px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 28px;
  }
  .content1-wrapper .content1-block-item .content1-block-span .content1-block-span-title {
    color: #FFFFFF;
  }
  .content1-wrapper .content1-block-item .content1-block-span .content1-block-span-line {
    background: #FFFFFF;
  }
  .content1-wrapper .content1-block-item .content1-block-span .content1-block-span-on {
    display: flex;
    line-height: 17px;
  }
  .content1-wrapper .content1-block-img-span {
    width: 100%;
  }
  .content1-wrapper .content1-block-img-span img {
    width: 100%;
    height: 198px;
    object-fit: cover;
  }
  .content1-wrapper .content1-img {
    height: 200px;
    padding: 0;
    text-align: center;
    margin-top: 64px;
  }
  .content1-wrapper .content1-img span {
    display: inline-block;
    width: 180px;
    height: 200px;
    line-height: 200px;
    margin: auto;
  }
  .content1-wrapper .content1-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
  }
  .content1-wrapper .content1-text .content1-content,
  .content1-wrapper .content1-text .content1-title {
    width: 100%;
    top: auto;
  }
  .content1-wrapper .content1-text .content1-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
  .content1-wrapper .content1-block {
    margin-bottom: 12px;
  }
}
.feature8-wrapper {
  min-height: 680px;
  margin: 0 auto;
  overflow: visible !important;
}
.feature8-wrapper.home-page-wrapper .home-page {
  padding: 0;
  margin-top: 260px;
}
.feature8 .secondTitle {
  text-align: center;
}
.feature8 .title-wrapper1 {
  position: absolute;
  top: -180px;
  left: calc(50% - 96px);
}
.feature8 .feature8-block-wrapper .feature8_wj_content .content_big_title {
  font-size: 28px;
  font-weight: 700;
  color: #1A1A1A;
  line-height: 38px;
  margin-top: 60px;
}
.feature8 .feature8-block-wrapper .feature8_wj_content .content_big_title::before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #1A1A1A;
  background: #000000;
  line-height: 28px;
  margin-right: 20px;
  margin-bottom: 5px;
}
.feature8 .feature8-block-wrapper .feature8_wj_content .content_second_title {
  display: block;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 28px;
  margin-top: 16px;
}
.feature8 .feature8-block-wrapper .feature8_wj_content .scan_code {
  display: block;
  margin-top: 16px;
}
.feature8 .feature8-block-wrapper .feature8_wj_content .scan_code .scan_code_item {
  display: inline-block;
  margin-right: 20px;
}
.feature8 .feature8-block-wrapper .feature8_wj_content .scan_code .scan_code_item img {
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.feature8 .feature8-block-wrapper .feature8-block-img {
  margin-top: -135px;
}
.feature8 .feature8-block-wrapper .feature8-block-img img {
  width: 340px;
}
@media screen and (max-width: 767px) {
  .feature8-wrapper {
    padding-bottom: 0;
    min-height: 834px;
  }
  .feature8-wrapper.home-page-wrapper .home-page {
    padding: 16px 16px 32px;
    margin-top: 0px;
  }
  .feature8 .lbhd4px2ci9-editor_css {
    height: 33px;
    font-size: 24px;
    font-weight: bold;
    color: #1A1A1A;
    line-height: 33px;
    text-align: center;
  }
  .feature8 .secondTitle {
    height: 11px;
    font-size: 8px !important;
    font-weight: 300;
    color: #1A1A1A;
    line-height: 11px;
    letter-spacing: 5px !important;
  }
  .feature8 .content_big_title_box .content_big_title {
    margin-top: -6px !important;
  }
  .feature8 .content_big_title {
    font-size: 14px !important;
    line-height: 20px !important;
    margin-top: 12px !important;
  }
  .feature8 .content_big_title::before {
    width: 6px !important;
    height: 6px !important;
    line-height: 20px !important;
    margin-right: 6px !important;
    margin-bottom: 3px !important;
  }
  .feature8 .feature8-block-wrapper .feature8_wj_content .content_second_title {
    font-size: 12px;
    line-height: 17px;
    margin-top: 4px;
  }
  .feature8 .feature8-block-wrapper .feature8_wj_content .scan_code {
    margin-top: -8px;
  }
  .feature8 .feature8-block-wrapper .feature8_wj_content .scan_code .scan_code_item img {
    width: 40px;
    height: 40px;
  }
  .feature8 .feature8-block-wrapper .feature8-block-img img {
    display: block;
    width: 240px;
    margin: 0 auto;
  }
  .feature8 .title-wrapper1 {
    position: unset;
  }
  .feature8 .scan_code_item {
    margin-bottom: 8px;
    margin-right: 8px !important;
  }
  .feature8 .feature8-block-img {
    margin-top: 0px !important;
  }
  .feature8-carousel-title {
    font-size: 16px;
  }
  .feature8-carousel-title-block {
    padding: 0 8px;
  }
  .feature8-block-child {
    width: 80%;
    margin: auto;
    background-color: #f6f9ff;
    box-shadow: 0 12px 36px rgba(100, 100, 136, 0.15);
  }
  .feature8-block-content {
    text-align: center;
  }
  .feature8-block-arrow {
    bottom: -40px;
    top: auto;
    left: 0;
    right: 0;
    margin: auto;
    transform: rotate(90deg);
  }
}
.teams1-wrapper {
  min-height: 446px;
  overflow: hidden;
}
.teams1-wrapper .teams1 {
  overflow: hidden;
  height: 100%;
  padding: 60px 0px;
}
.teams1-wrapper .teams1 > .title-wrapper {
  margin: 0 auto 60px;
}
.teams1-wrapper .teams1 .block-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
  padding: 20px 0;
  display: flex;
}
.teams1-wrapper .teams1 .block-wrapper .block {
  display: inline-block;
  text-align: center;
  margin-bottom: 20px;
  background: #F1F2F5;
  padding: 8px 0;
  border-radius: 4px;
  max-width: 186px;
  margin: 0 auto 12px;
}
.teams1-wrapper .teams1 .block-wrapper .block.queue-anim-leaving {
  position: relative !important;
}
.teams1-wrapper .teams1 .secondBigTitle-editor_css {
  font-size: 24px;
  font-weight: bold;
  color: #1A1A1A;
  line-height: 33px;
}
.teams1-wrapper .teams1 .secondBigTitle-editor_css::before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #000000;
  line-height: 33px;
  margin-right: 12px;
  margin-bottom: 5px;
}
.teams1-wrapper .teams1 .secondBigTitle-editor_css .secondBigTitle1-editor_css {
  color: #004BC7;
}
.teams1-wrapper .teams1 .secondBigTitle-editor_css .apply_css {
  position: absolute;
  right: 18px;
  top: 7px;
  text-decoration: underline;
  width: 56px;
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #004BC7;
  line-height: 20px;
  cursor: pointer;
}
.teams1-wrapper .teams1 .secondBigTitleImg-editor_css {
  margin-top: 20px;
  width: 100%;
}
.teams1-wrapper .teams1-image,
.teams1-wrapper .teams1-title,
.teams1-wrapper .teams1-job,
.teams1-wrapper .teams1-content {
  width: 170px;
  margin: auto;
  line-height: 1.5;
  border-radius: 2px;
}
.teams1-wrapper .teams1-image {
  color: #404040;
}
.teams1-wrapper .teams1-image img {
  width: 100%;
}
.teams1-wrapper .teams1-title {
  font-size: 24px;
  margin: 24px auto 8px;
}
.teams1-wrapper .teams1-job {
  margin: 8px auto;
}
.teams1-wrapper .teams1-job,
.teams1-wrapper .teams1-content {
  font-size: 14px;
  color: #1A1A1A;
  text-align: left;
}
.teams1-wrapper .teams1-job-name-position {
  display: flex;
  justify-content: space-between;
}
.teams1-wrapper .teams1-job-name {
  font-size: 16px;
  font-weight: bold;
  color: #1A1A1A;
  line-height: 22px;
}
.teams1-wrapper .teams1-job-position {
  font-size: 16px;
  font-weight: bold;
  color: #004BC7;
  line-height: 22px;
}
.teams1-wrapper .teams1-first-block-image {
  margin-right: 15px;
}
.teams1-wrapper .teams1-second-block-image {
  margin-left: 15px;
}
@media screen and (max-width: 767px) {
  .teams1-wrapper {
    padding: 32px 0 0 0;
    min-height: 964px;
  }
  .teams1-wrapper .lbhd4px2ci9-editor_css {
    height: 33px;
    font-size: 24px;
    font-weight: bold;
    color: #1A1A1A;
    line-height: 33px;
  }
  .teams1-wrapper .secondTitle {
    height: 11px;
    font-size: 8px !important;
    font-weight: 300;
    color: #1A1A1A;
    line-height: 11px;
    letter-spacing: 3px !important;
  }
  .teams1-wrapper .teams1-image,
  .teams1-wrapper .teams1-title,
  .teams1-wrapper .teams1-job,
  .teams1-wrapper .teams1-content {
    width: 100px;
  }
  .teams1-wrapper .teams1 {
    padding: 0;
  }
  .teams1-wrapper .teams1 .secondBigTitle-editor_css {
    height: 20px;
    font-size: 14px;
    font-weight: bold;
    color: #1A1A1A;
    line-height: 20px;
    padding: 0 16px;
    margin-bottom: 8px;
  }
  .teams1-wrapper .teams1 .secondBigTitle-editor_css::before {
    width: 6px;
    height: 6px;
    margin-right: 6px;
    margin-bottom: 2px;
  }
  .teams1-wrapper .teams1 .block-wrapper .block {
    max-width: 136px;
    padding: 5px 0;
    margin: 0 auto 8px;
  }
  .teams1-wrapper .teams1 .block-wrapper {
    padding: 8px 10px 0 !important;
  }
  .teams1-wrapper .teams1 .secondBigTitleImg-editor_css {
    margin-top: 0;
  }
  .teams1-wrapper .teams1-job-name {
    height: 17px;
    font-size: 12px;
    font-weight: bold;
    color: #1A1A1A;
    line-height: 17px;
  }
  .teams1-wrapper .teams1-job-position {
    height: 17px;
    font-size: 12px;
    font-weight: bold;
    color: #004BC7;
    line-height: 17px;
  }
  .teams1-wrapper .teams1-job,
  .teams1-wrapper .teams1-content {
    font-size: 10px;
    font-weight: 400;
    color: #1A1A1A;
    line-height: 14px;
  }
  .teams1-wrapper .teams1-first-block-image,
  .teams1-wrapper .teams1-second-block-image {
    margin-right: 6px;
    margin-bottom: 12px;
    margin-left: 6px;
  }
  .teams1-wrapper .teams1-first-block-image .secondBigTitle-editor_css,
  .teams1-wrapper .teams1-second-block-image .secondBigTitle-editor_css {
    padding: 0;
  }
}
.content5-wrapper .content5 {
  min-height: 720px;
}
.content5-wrapper .content5-block {
  background: #002EA1;
  color: #ffffff;
  height: 720px;
}
.content5-wrapper .content5-block-box {
  padding: 0 14%;
}
.content5-wrapper .content5-block-span {
  width: 304px;
}
.content5-wrapper .content5-desc {
  margin: 20px 0;
}
.content5-wrapper .content5-big-title {
  height: 38px;
  font-size: 28px;
  font-weight: 700;
  color: #FFFFFF;
  line-height: 38px;
  margin-bottom: 32px;
  margin-top: 60px;
}
.content5-wrapper .content5-big-title::before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #FFFFFF;
  line-height: 33px;
  margin-right: 12px;
  margin-bottom: 5px;
}
.content5-wrapper .content5-samll-title {
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 22px;
  margin-bottom: 4px;
}
.content5-wrapper .content5-relation {
  display: flex;
  align-items: flex-end;
  margin-top: 88px;
}
.content5-wrapper .content5-relation p {
  height: 22px;
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 22px;
}
.content5-wrapper .content5-relation-tel-rext {
  opacity: 0.7;
}
.content5-wrapper .content5-relation-scan {
  margin-left: 24px;
}
.content5-wrapper .content5-relation-scan img {
  display: block;
  width: 80px;
  height: 80px;
  margin-bottom: 4px;
}
.content5-wrapper .content5-slider-item {
  position: relative;
}
.content5-wrapper .content5-slider-item-text {
  position: absolute;
  top: 80px;
  left: 14%;
  height: 33px;
  font-size: 24px;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 33px;
  text-shadow: 0px 2px 2px #000000;
}
.content5-wrapper .content5-slider-item-text::before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #FFFFFF;
  line-height: 33px;
  margin-right: 12px;
  margin-bottom: 5px;
}
.content5-wrapper .content5-slider-item img {
  object-fit: cover;
}
.content5-slider-item {
  height: 720px;
}
.content5-slider-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.slick-arrow {
  display: none;
}
.slick-dots {
  bottom: 60px;
  text-align: left;
  margin-left: 14%;
  display: flex !important;
  align-items: flex-end;
}
.slick-dots li {
  width: 60px;
  height: 60px;
  border-radius: 2px;
  margin: 0 10px;
  border: 2px solid #ffffff;
  opacity: 0.7;
}
.slick-dots li img {
  width: 56px;
  height: 56px;
  border-radius: 2px;
}
.slick-dots .slick-active {
  width: 100px;
  height: 100px;
  border: 3px solid #ffffff;
  border-radius: 2px;
  opacity: 0.7;
}
.slick-dots .slick-active img {
  width: 94px;
  height: 94px;
  border-radius: 2px;
}
@media screen and (max-width: 767px) {
  .content5-wrapper {
    min-height: 1035px;
    overflow: hidden;
    margin-bottom: 24px;
  }
  .content5-wrapper .content5-slider-item-text {
    font-size: 16px;
    line-height: 22px;
    text-shadow: 0px 2px 2px #000000;
    top: 28px;
    left: 16px;
  }
  .content5-wrapper .content5-slider-item-text::before {
    content: '';
    width: 0px;
    height: 0px;
  }
  .content5-wrapper .content5-slider-item {
    height: 568px;
  }
  .content5-wrapper .content5-slider-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .content5-wrapper .slick-dots {
    margin-left: 16px;
  }
  .content5-wrapper .slick-dots li {
    width: 40px;
    height: 40px;
    border-radius: 2px;
    margin: 0 4px;
    border: 1px solid #ffffff;
    opacity: 0.7;
  }
  .content5-wrapper .slick-dots li img {
    width: 38px;
    height: 38px;
    border-radius: 2px;
  }
  .content5-wrapper .slick-dots .slick-active {
    width: 60px;
    height: 60px;
    border: 2px solid #ffffff;
    border-radius: 2px;
    opacity: 0.7;
  }
  .content5-wrapper .slick-dots .slick-active img {
    width: 56px;
    height: 56px;
    border-radius: 2px;
  }
  .content5-wrapper .slick-slider .slick-prev,
  .content5-wrapper .slick-slider .slick-next {
    display: none !important;
  }
  .content5-wrapper .slick-track {
    height: 568px;
  }
  .content5-wrapper .content5-block {
    height: auto;
    padding-bottom: 24px;
  }
  .content5-wrapper .content5-block-box {
    padding: 0 16px;
  }
  .content5-wrapper .content5-block-box .content5-block-span {
    width: 100%;
  }
  .content5-wrapper .content5-block-box .content5-block-span .content5-big-title {
    font-size: 20px;
    margin-bottom: 14px;
    margin-top: 24px;
    line-height: 27px;
  }
  .content5-wrapper .content5-block-box .content5-block-span .content5-desc {
    margin: 16px 0;
  }
  .content5-wrapper .content5-block-box .content5-block-span .content5-samll-title {
    font-size: 14px;
  }
  .content5-wrapper .content5-block-box .content5-block-span p {
    font-size: 12px;
  }
  .content5-wrapper .content5-block-box .content5-block-span .content5-relation {
    margin-top: 32px;
  }
  .content5-wrapper .content5-block-box .content5-block-span .content5-relation .content5-relation-tel-num {
    font-size: 14px;
  }
}
.footer1-wrapper {
  background: #001529;
  overflow: hidden;
  position: relative;
  min-height: 360px;
  color: #999;
}
.footer1-wrapper .footer1 .home-page {
  padding: 64px 24px 80px;
}
.footer1-wrapper .block {
  padding: 0 32px;
}
.footer1-wrapper .block .logo {
  max-width: 180px;
}
.footer1-wrapper .block .slogan {
  font-size: 12px;
  margin-top: -20px;
}
.footer1-wrapper .block > h2 {
  margin-bottom: 24px;
  color: #ccc;
}
.footer1-wrapper .block a {
  color: #999;
  margin-bottom: 12px;
  float: left;
  clear: both;
}
.footer1-wrapper .block a:hover {
  color: #1890ff;
}
.footer1-wrapper .block .content_big_title {
  height: 33px;
  font-size: 24px;
  font-weight: 700;
  color: #222222;
  line-height: 33px;
}
.footer1-wrapper .block .content_second_title {
  height: 22px;
  font-size: 16px;
  font-weight: 400;
  color: #222222;
  line-height: 22px;
  margin-top: 4px;
}
.footer1-wrapper .block .feature_wj_content {
  display: block;
  margin-right: 68px;
}
.footer1-wrapper .block .scan_code {
  display: flex;
  margin-top: 40px;
}
.footer1-wrapper .block .scan_code .scan_code_item {
  margin-right: 20px;
}
.footer1-wrapper .block .scan_code .scan_code_item img {
  width: 68px;
  height: 68px;
}
.footer1-wrapper .block .scan_imgs .scan_img_item {
  width: 88px;
  height: 88px;
}
.footer1-wrapper .block .content_address {
  display: block;
  margin-top: 24px;
  font-size: 14px;
  color: #222222;
  line-height: 20px;
}
.footer1-wrapper .block .content_address .content_address_item {
  margin-top: 4px;
}
.footer1-wrapper .block .online_scan .online_scan_code {
  width: 142px;
  height: 142px;
  border-radius: 2px;
  margin-top: 17px;
}
.footer1-wrapper .block .online_scan .online_scan_span {
  font-size: 16px;
  color: #222222;
  line-height: 22px;
  margin-top: 12px;
}
.footer1-wrapper .copyright-wrapper {
  width: 100%;
  border-top: 1px solid rgba(233, 233, 233, 0.1);
}
.footer1-wrapper .copyright-wrapper .home-page {
  padding: 0 24px;
  overflow: hidden;
}
.footer1-wrapper .copyright-wrapper .copyright {
  height: 80px;
  text-align: center;
  line-height: 80px;
}
@media screen and (max-width: 767px) {
  .footer1 {
    min-height: 417px;
  }
  .footer1-wrapper .footer1 .home-page {
    padding: 64px 24px 32px;
  }
  .footer1 .content_big_title {
    height: 22px !important;
    font-size: 16px !important;
    color: #1A1A1A !important;
    line-height: 22px !important;
  }
  .footer1 .content_second_title {
    height: 17px !important;
    font-size: 12px !important;
    color: #1A1A1A !important;
    line-height: 17px !important;
  }
  .footer1 .feature_wj_content {
    margin-right: 0 !important;
  }
  .footer1 .feature_wj_content .scan_code {
    padding: 0 4%;
  }
  .footer1 .content_second_title a {
    margin: 0 auto;
  }
  .footer1 .logo {
    margin: 0 auto 24px;
  }
  .footer1 .block {
    text-align: center;
    padding: 0;
  }
  .footer1 .block .scan_code {
    height: 50px;
    margin-top: 12px;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
  }
  .footer1 .block .scan_code .scan_code_item img {
    width: 50px;
    height: 50px;
  }
  .footer1 .block .scan_code .scan_code_item:last-child {
    margin-right: 0 !important;
  }
  .footer1 .block .content_address {
    margin-top: 24px;
    display: block;
  }
  .footer1 .block .content_address .content_address_desc {
    height: 22px;
    font-size: 16px;
    font-weight: bold;
    color: #1A1A1A;
    line-height: 22px;
  }
  .footer1 .block .content_address .content_address_item {
    height: 17px;
    font-size: 12px;
    font-weight: 400;
    color: #1A1A1A;
    line-height: 17px;
    margin-top: 4px;
  }
  .footer1 .online_scan .online_scan_code {
    width: 95px !important;
    height: 95px !important;
  }
  .footer1 .online_scan .online_scan_span {
    font-size: 12px !important;
    margin-top: 4px !important;
    color: #1A1A1A !important;
  }
  .footer1 > ul {
    width: 90%;
    margin: 20px auto 0;
    padding: 10px 0;
  }
  .footer1 > ul > li {
    width: 100%;
  }
  .footer1 > ul > li h2 {
    margin-bottom: 10px;
  }
  .footer1 > ul > li li {
    display: inline-block;
    margin-right: 10px;
  }
  .footer1 .copyright-wrapper .home-page {
    padding: 0;
  }
  .footer1 .copyright-wrapper .home-page .copyright {
    font-size: 12px;
  }
  .footer1 .copyright span {
    width: 90%;
  }
}
.footer0-wrapper {
  background-color: #001529;
  overflow: hidden;
}
.footer0-wrapper .footer0 {
  height: 100%;
  padding: 30px 24px;
  text-align: center;
  color: #ffffff;
  position: relative;
}
@media screen and (max-width: 767px) {
  .footer0-wrapper {
    padding: 30px 0;
  }
  .footer0-wrapper .footer0 {
    font-size: 12px;
  }
  .footer0-wrapper .footer0.home-page {
    padding: 0;
  }
  .footer0-wrapper .footer0 > div {
    width: 90%;
    margin: auto;
  }
}
#Content0_0 .ant-col > .content0-block-item > .lbei0fb72ct-editor_css {
  font-size: 18px;
  color: #666;
}
#Content0_0 .ant-col > .content0-block-item > .lbei0wb3km-editor_css {
  font-size: 18px;
  color: #666;
}
#Content0_0 .ant-col > .content0-block-item > .lbei18awme7-editor_css {
  font-size: 18px;
  color: #666;
}
#Content0_0 .ant-col > .content0-block-item > .lbei1e2arid-editor_css {
  font-size: 18px;
  color: #666;
}
#Content0_0 .ant-col > .content0-block-item > .lbei1s00foh-editor_css {
  font-size: 18px;
  color: #666;
}
#Content0_0 .ant-col > .content0-block-item > .lbei2048t0g-editor_css {
  font-size: 18px;
  color: #666;
}
#Content0_0 .ant-col > .content0-block-item > .lbei088alwt-editor_css {
  font-weight: normal;
  color: #333333;
}
#Content0_0 .ant-col > .content0-block-item > .lbei0r3llmo-editor_css {
  font-weight: normal;
  color: #333333;
}
#Content0_0 .ant-col > .content0-block-item > .lbei13gyay9-editor_css {
  font-weight: normal;
  color: #333333;
}
#Content0_0 .ant-col > .content0-block-item > .lbei1cfxh8-editor_css {
  font-weight: normal;
  color: #333333;
}
#Content0_0 .ant-col > .content0-block-item > .lbei1lbstwf-editor_css {
  font-weight: normal;
  color: #333333;
}
#Content0_0 .ant-col > .content0-block-item > .lbei1thr87-editor_css {
  font-weight: normal;
  color: #333333;
}
#Feature6_0 .feature6-content-wrapper > .feature6-title-wrapper > .lbeia0a0mu-editor_css {
  height: 0;
}
#Feature6_0 .feature6-title-wrapper > .feature6-title > .lbeibtwycr-editor_css {
  border-width: 0;
}
#Feature6_0 .feature6-title > .feature6-title-bar-wrapper > .lbeicvexglp-editor_css {
  width: 0;
}
#Feature6_0 .home-page > .feature6-content-wrapper > .lbeid8rxr4-editor_css {
  display: none;
}
#Feature6_0 .ant-row > .ant-col > .lbeidqmer1l-editor_css {
  display: inline-block;
}
#Feature6_0 .ant-row > .ant-col > .lbeihz0ind-editor_css {
  display: inline-block;
}
#Feature6_0 .ant-row > .ant-col > .lbeiij197sc-editor_css {
  display: inline-block;
}
#Feature8_0 .home-page > .feature8-title-wrapper > .lbej376s9f-editor_css {
  display: none;
}
#Feature8_0 .ant-row > .ant-col > .lbej40di37i-editor_css {
  display: none;
}
#Feature8_0 .ant-row > .ant-col > .lbej42oxc68-editor_css {
  display: none;
}
#Feature8_0 .ant-row > .ant-col > .lbej44yvjt6-editor_css {
  display: none;
}
#Feature8_0 div > .ant-row > .lbej4o9c3an-editor_css {
  display: none;
}
#Feature8_0 .feature8-block > .feature8-button-wrapper > .lbej5ycyhad-editor_css {
  display: none;
}
#Footer1_0 .ant-row > .ant-col > .lbeoejr8j9k-editor_css {
  display: none;
}
#Footer1_0 .footer1 > .lbeogz1gge-editor_css {
  display: none;
}
#Content5_1 .ant-col > .content5-block-content > .lbeqlbrcpoj-editor_css {
  display: none;
}
#Content5_1 .home-page > .title-wrapper > .lbeqyfkwnsi-editor_css {
  display: none;
}
#Content0_0 .home-page > .title-wrapper > .lberfcje1bh-editor_css {
  font-size: 30px;
  font-weight: normal;
  line-height: 1.0715;
  color: rgba(49, 70, 89, 0.2);
}
#Content0_0 .home-page > .title-wrapper > .lbergt59f1-editor_css {
  margin: 0px 0px;
}
#Content0_0 .lbeim13sng-editor_css {
  padding: 64px 24px;
}
#Feature8_0 .home-page > .feature8-title-wrapper > .lberjl83w7-editor_css {
  font-size: 30px;
  line-height: 1.0715;
  color: rgba(195, 194, 194, 0.46);
}
#Feature8_0 .home-page > .feature8-title-wrapper > .lberiibt56d-editor_css {
  margin: 0px 0;
}
#Content5_1 .lberotx31nh-editor_css {
  padding: 64px 24px;
}
#Content5_1 .home-page > .title-wrapper > .lberpmnocgo-editor_css {
  font-size: 30px;
  font-weight: normal;
  line-height: 1.0715;
  color: rgba(0, 0, 0, 0.2);
}
#Content5_1 .home-page > .title-wrapper > .lberor5c00n-editor_css {
  margin: 0px 0;
}
#Content1_0 {
  background-image: url('http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/P2yRGNkeQDjAG72nbDMmxydpE2bs4Hmr_1691717347926_image.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 60px 0 120px;
}
#Content1_0 .ant-row > .lbes4x4idmf-editor_css {
  align-items: center;
  justify-content: center;
}
#Feature8_0 {
  background-image: url('http://mall-hangzhou.oss-cn-hangzhou.aliyuncs.com/banner/2023-08-11/MyaCJSdpPWDSPNXPG78fQ2SSm3yJjj5t_1691717455888_image.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#Feature8_0 .ant-col > .feature8-block-child > .lbg9mp50h8h-editor_css {
  margin: -1rem 0 0.5em;
}
#Feature8_0 .ant-col > .feature8-block-child > .lbg9ouyebvh-editor_css {
  margin: -1rem 0 0.5em;
}
#Feature8_0 .ant-col > .feature8-block-child > .lbg9p00dgc-editor_css {
  margin: -1rem 0 0.5em;
}
#Feature8_0 .ant-row > .ant-col > .lbg9rpgv2z9-editor_css {
  background-clip: padding-box;
}
#Teams1_0 .home-page > .title-wrapper > .lbg9umlij0e-editor_css {
  color: rgba(0, 0, 0, 0.2);
}
#Teams1_0 .ant-row > .ant-col > .lbg9wystv7-editor_css {
  text-align: left;
}
#Teams1_0 .ant-row > .ant-col > .lbg9w64vgqr-editor_css {
  text-align: left;
}
#Teams1_0 .ant-row > .ant-col > .lbg9wuaiktk-editor_css {
  text-align: left;
}
#Content5_3 .home-page > .title-wrapper > .lbgas0735o-editor_css {
  display: none;
}
#Content5_3 .home-page > .title-wrapper > .lbgasb6tr9i-editor_css {
  color: rgba(0, 0, 0, 0.2);
}
#Content5_3 .ant-col > .content5-block-content > .lbgatj7mwkm-editor_css {
  display: none;
}
#Content5_3 .home-page > div > .lbgc2k9hsbs-editor_css {
  justify-content: space-between;
}
#Content5_1 .ant-row > .ant-col > .lbgc82c2kg-editor_css {
  padding: 0;
}
#Content5_1 .home-page > div > .lber2f25v2i-editor_css {
  justify-content: center;
}
#Content5_1 .ant-col > .content5-block-content > .lbeqzj96tuj-editor_css {
  height: 100px;
  background-color: rgba(0, 0, 0, 0);
}
#Content5_1 div > .ant-row > .lbeqnkfx0y6-editor_css {
  margin: 0px 16px 32px;
  padding: 0px 0px;
}
#Content5_4 .home-page > .title-wrapper > .lbha9l966o-editor_css {
  display: none;
}
#Content5_4 .home-page > .title-wrapper > .lbha9pyh6l-editor_css {
  font-size: 30px;
  color: rgba(0, 0, 0, 0.2);
}
#Content5_4 .ant-col > .content5-block-content > .lbhaaj6sltc-editor_css {
  display: none;
}
#Content5_4 .ant-row > .ant-col > .lbhafgdp0n-editor_css {
  padding: 0;
}
#Content5_4 .home-page > div > .lbhaaxnfud-editor_css {
  justify-content: center;
}
#Content1_0 .ant-row > .ant-col > .lbhc43gjl6j-editor_css {
  width: 500px;
}
#Content1_0 .ant-row > .ant-col > .lbeiuabt1e-editor_css {
  font-size: 18px;
}
#Content1_0 .ant-row > .lbhc42l7him-editor_css {
  align-items: flex-start;
  margin: 50px 0 0;
}
#Content1_0 .ant-row > .ant-col > .lbhc6a20iup-editor_css {
  margin: 50px 0 0.5em;
}
#Teams1_0.lbg9uv3jqbn-editor_css {
  background-attachment: scroll;
  background-blend-mode: normal;
  background-position: 0% 0%;
  background-repeat: no-repeat;
  background-size: 100%;
  background-clip: padding-box;
}
#Content5_3 div > .ant-row > .lbgauliceka-editor_css {
  background-color: #ffffff;
  margin: 0px 16px 38px;
  padding: 0;
}
#Content5_1.lbhc9r0xci-editor_css {
  min-height: 520px;
}
#Content5_4 .home-page > .title-wrapper > .lbhcazc3qe-editor_css {
  margin: 0px 0;
}
#Content5_4.lbhcc5kraqn-editor_css {
  min-height: 520px;
}
#Content5_3 .home-page > .title-wrapper > .lbhccwtshv-editor_css {
  margin: 0px 0;
}
#Content5_3.lbhc99rif4j-editor_css {
  min-height: 520px;
}
#Footer1_0 .ant-col > .slogan > .lbhbiht810e-editor_css {
  display: none;
  margin: 16px 0px 0px;
}
#Footer1_0 .ant-col > .slogan > .lbhct1rlm9-editor_css {
  font-size: 14px;
  color: #cccccc;
  margin: 16px 0 0.5em;
}
#Teams1_0 .home-page > .title-wrapper > .lbhd4px2ci9-editor_css {
  margin: 0px 0;
}
#Feature8_0 .ant-col > .feature8-block-child > .lbhdbariafl-editor_css {
  display: flex;
  align-items: center;
  justify-content: center;
}
#Feature8_0 .ant-col > .feature8-block-child > .lbhdf4sy1f-editor_css {
  display: flex;
  align-items: center;
  justify-content: center;
}
#Feature8_0 .ant-col > .feature8-block-child > .lbhdf7xgcac-editor_css {
  display: flex;
  align-items: center;
  justify-content: center;
}
#Content5_3 .ant-col > .content5-block-content > .lbgatwa1lgo-editor_css {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 180px;
  background-color: rgba(0, 0, 0, 0);
  padding: 0px 0px;
}
#Content5_3 .ant-row > .ant-col > .lbgatmatyfd-editor_css {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0);
  padding: 0px 0px;
}
#Content5_4 div > .ant-row > .lbhab42dqg-editor_css {
  background-color: #ffffff;
  margin: 0px 16px 32px;
}
#Content5_4 .ant-col > .content5-block-content > .lbhadoeifkd-editor_css {
  height: 100px;
  background-color: rgba(0, 0, 0, 0);
  padding: 0px 0px;
}
#Footer1_0 .footer1 > .lbeodts2h2-editor_css {
  align-items: center;
  min-height: 200px;
  padding: 0px 0px;
}
#Footer1_0 .ant-row > .ant-col > .lbhcgcpeyds-editor_css {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  margin: 0px 0px;
  padding: 0 0px 0px;
}
#Feature6_0.lbeijeo5u4-editor_css {
  background-clip: padding-box;
  min-height: 260px;
}
#Content0_0.lbhduynf6t-editor_css {
  background-clip: padding-box;
}
#Feature8_0.lbhdxkqbktk-editor_css {
  background-attachment: scroll;
  background-blend-mode: normal;
  background-position: 0% 0%;
  background-repeat: repeat;
  background-size: auto;
  background-clip: padding-box;
}
#Content1_0.lbes45zkvv-editor_css {
  background-clip: padding-box;
}
#Footer1_0 .ant-col > .slogan > .lbeonl7mww-editor_css {
  justify-content: center;
  min-width: 140px;
  min-height: 134px;
  background-attachment: scroll;
  background-blend-mode: normal;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-clip: padding-box;
  margin: 0px 0px;
}
#Footer0_0.lbhj0f0pkvh-editor_css {
  color: #ffffff;
  background-color: #001B82;
  line-height: 17px;
}
#Nav2_0.lbeouxyg9dm-editor_css {
  position: fixed;
}
#Nav2_0 .home-page > .header2-menu > .lbklk2a7bl-editor_css {
  font-weight: bold;
}
#Nav2_0 .home-page > .lbklkexw95p-editor_css {
  font-weight: bold;
}
#Banner5_0 .home-page > .lbnbbwdgnx-editor_css {
  display: none;
}
#Banner5_0 .home-page {
  background-color: #1A1A1A;
  margin: 0px auto auto;
}
#Banner5_0 .banner5-page > .banner5-image {
  display: block;
}
#Banner5_0 .banner5-page {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
#Banner5_0 .banner5-page .middleCon {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 99;
  background: rgba(26, 26, 26, 0.5);
}
#Banner5_0 .banner5-page .middleCon .middleCon_box {
  width: 860px;
  height: 267px;
  margin-top: calc(50vh - 133px);
  margin-left: calc(50% - 430px);
}
#Banner5_0 .banner5-page .middleCon .middle_title {
  width: 100%;
  height: 82px;
  font-size: 60px;
  font-weight: 800;
  color: #FFFFFF;
  line-height: 82px;
  letter-spacing: 1px;
}
#Banner5_0 .banner5-page .middleCon .middle_describe {
  width: 100%;
  height: 33px;
  font-size: 24px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 33px;
  margin-top: 24px;
  text-align: center;
}
#Banner5_0 .banner5-page .middleCon .middle_consult {
  width: 100%;
  height: 48px;
  margin-top: 80px;
}
#Banner5_0 .banner5-page .middleCon .middle_consult .middle_btn {
  width: 160px;
  height: 48px;
  background: #004BC7;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 48px;
  text-align: center;
  float: left;
  position: relative;
}
#Banner5_0 .banner5-page .middleCon .middle_consult .middle_btn .middle_btn1_span {
  color: #ffffff;
}
#Banner5_0 .banner5-page .middleCon .middle_consult .middle_btn:hover {
  cursor: pointer;
  background: #ffffff;
  color: #004BC7;
}
#Banner5_0 .banner5-page .middleCon .middle_consult .middle_btn:hover .middle_btn_3 {
  display: block;
}
#Banner5_0 .banner5-page .middleCon .middle_consult .middle_btn:hover .middle_btn1_span {
  color: #004BC7 !important;
}
#Banner5_0 .banner5-page .middleCon .middle_consult .middle_btn .middle_btn_3 {
  display: none;
}
#Banner5_0 .banner5-page .middleCon .middle_consult .middle_btn .middle_btn1_1 {
  color: #1A1A1A;
  font-weight: 600;
  font-size: 16px;
}
#Banner5_0 .banner5-page .middleCon .middle_consult .middle_btn .middle_btn1_1 .middle_btn1_1_img {
  width: 20px;
  height: 20px;
  margin-right: 4px;
  margin-top: -3px;
}
#Banner5_0 .banner5-page .middleCon .middle_consult .middle_btn1 {
  margin-left: 250px;
}
#Banner5_0 .banner5-page .middleCon .middle_consult .middle_btn1:hover {
  height: 102px;
}
#Banner5_0 .banner5-page .middleCon .middle_consult .middle_btn2 {
  margin-left: 40px;
}
#Banner5_0 .banner5-page .middleCon .middle_consult .middle_btn2:hover {
  height: 230px;
}
#Banner5_0 .banner5-page .middleCon .middle_consult .middle_btn2 .middle_btn2_1 .middle_btn2_1_img {
  width: 120px;
  height: 120px;
  margin-top: 20px;
}
#Banner5_0 .banner5-page .middleCon .middle_consult .middle_btn2 .middle_btn2_1 .middle_btn2_1_span {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  margin-top: 4px;
}
#Footer0_0 .home-page > .lbhixjswdva-editor_css {
  background-clip: padding-box;
  border-top-color: initial;
  font-size: 12px;
  border-top-style: solid;
  border-top-width: 0px;
}
#Footer1_0 .ant-row > .ant-col > .lbhch4vcot8-editor_css {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0px 0px;
  padding: 0px 0px;
}
#Footer1_0 .ant-col > .slogan > .lbnd24mdme7-editor_css {
  width: 100%;
  font-size: 14px;
  padding: 0px 0px;
}
#Footer1_0 .lbeoh9r3xrb-editor_css {
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
}
#Footer1_0.lbeohbue8rs-editor_css {
  align-items: center;
  justify-content: center;
  min-height: 280px;
  background-color: #ECF2FE;
  padding: 40px 0;
}
#Footer1_0 .footer1 > .ant-row > .lbeof0e8n5-editor_css {
  align-items: center;
  padding: 0px 0px;
}
#Nav2_0 .home-page > .header2-menu > .lbndte7gi36-editor_css {
  align-items: center;
}
#Banner5_0 .lbndsr1n99i-editor_css {
  width: 100%;
  height: 100vh;
  margin: 0px auto auto;
}
#Banner5_0 .home-page {
  width: 100% !important;
  height: 100vh !important;
  max-width: 100% !important;
}
#Banner5_0 .home-page > .lbnbc8g0wcp-editor_css {
  display: none;
  width: 10px;
  max-width: 1200px;
  height: 10px;
  right: 0px;
}
#Banner5_0.lbnbdit1rtf-editor_css {
  background-color: rgba(0, 0, 0, 0);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.box-ant-back-top {
  position: fixed;
  bottom: 100px;
  right: 20px;
  z-index: 10000;
}
.box-ant-back-top1 {
  position: fixed;
  bottom: 250px;
  right: 20px;
  z-index: 10000;
}

